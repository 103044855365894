import {
  Button,
  ButtonVariant,
  Dialog,
  DocumentIcon,
  FocusArea,
  GiftIcon,
  Input,
  MenuItem,
  MenuList,
  MoreIcon,
  offset,
  PlusIcon,
  Popover,
  Portfolio,
  toast,
  useTranslation,
} from '@grunfin/ui-kit';
import { Link } from 'react-router-dom';
import 'twin.macro';

import { PortfolioStatus } from '../types';
import { InvestmentImpact } from './InvestmentImpact';
import InvestmentPredictionGraph from './InvestmentPredictionGraph';
import { PortfolioFigures, SimplePortfolioFigures } from './PortfolioFigures';
import { StatusBanner } from './StatusBanner';
import { Title } from './Title';
import climateImage from '~/assets/output/portfolio_impact_climate.webp';
import healthImage from '~/assets/output/portfolio_impact_health.webp';
import diversityImage from '~/assets/output/portfolio_impact_diversity.webp';
import { useForm } from 'react-hook-form';
import { useApplyVoucher, useValidateVoucher } from '../../gift/voucher/queries';
import { setBackendValidationErrors } from '~/utils';
import { useState } from 'react';
import { trackRedeem } from '~/utils/tracking-analytics';

interface Props {
  portfolio: Portfolio;
}

export const ActiveItem = ({ portfolio }: Props) => {
  return (
    <>
      <Title portfolio={portfolio}>
        <Buttons portfolio={portfolio} />
      </Title>
      <StatusBanner portfolio={portfolio} />
      <PortfolioFigures portfolio={portfolio} />
      <InvestmentImpactWithGraph portfolio={portfolio} />
    </>
  );
};

export const ExitedItem = ({ portfolio }: Props) => {
  return (
    <>
      <Title portfolio={portfolio}>
        <Buttons portfolio={portfolio} />
      </Title>
      <SimplePortfolioFigures portfolio={portfolio} />
      <InvestmentImpact portfolio={portfolio} />
    </>
  );
};

const InvestmentImpactWithGraph = ({ portfolio }: Props) => {
  const lastFocusArea = portfolio.focus.split(', ').pop();

  const list = {
    [FocusArea.CLIMATE_CHANGE]: climateImage,
    [FocusArea.HEALTH]: healthImage,
    [FocusArea.GENDER_EQUALITY]: diversityImage,
    [FocusArea.SP500]: climateImage,
  };

  const variantKey = Object.keys(FocusArea)[Object.values(FocusArea).indexOf(lastFocusArea as FocusArea)];

  return (
    <div tw="relative">
      <div
        tw="absolute left-0 bottom-0 w-full h-full"
        style={{
          zIndex: -1,
          background:
            'linear-gradient(190deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0.64) 60%, rgba(255,255,255,0) 100%)',
        }}
      />
      {lastFocusArea != null && (
        <img
          src={(list as any)[lastFocusArea]}
          alt=""
          tw="w-full h-full absolute left-0 bottom-0 -z-[2] rounded-b-2xl"
        />
      )}
      <InvestmentPredictionGraph portfolio={portfolio} />
      <InvestmentImpact portfolio={portfolio} variant={FocusArea[variantKey as keyof typeof FocusArea]} />
    </div>
  );
};

const Buttons = ({ portfolio }: Props) => {
  const { t } = useTranslation('portfolio');
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div tw="flex">
      <Button variant={ButtonVariant.SECONDARY} tw="mr-4">
        <DocumentIcon />
        <Link to={`/portfolio/${portfolio.id}/reports`}>{t('reports')}</Link>
      </Button>
      <Popover
        showOnFocus={false}
        showOnHover={false}
        options={{ middleware: [offset(20)] }}
        content={
          <div>
            <MenuList>
              {portfolio.status === PortfolioStatus.ACTIVE && (
                <>
                  <Link to={`/portfolio/${portfolio.id}/contribution`}>
                    <MenuItem>
                      <GiftIcon tw="rounded-md bg-sky-blue-50 text-sky-blue-700" /> {t('get_gifts.title')}
                    </MenuItem>
                  </Link>
                  <ApplyVoucherForPortfolioDialog
                    open={isModalOpen}
                    onOpenChange={setIsModalOpen}
                    portfolioId={portfolio.id}
                  >
                    <Link to="#">
                      <MenuItem>
                        <PlusIcon tw="rounded-md bg-sky-blue-50 text-sky-blue-700" />{' '}
                        {t('dashboard.gift_card.apply_voucher')}
                      </MenuItem>
                    </Link>
                  </ApplyVoucherForPortfolioDialog>
                </>
              )}
              <Link to={`/portfolio/${portfolio.id}`}>
                <MenuItem>
                  <MoreIcon tw="rounded-md bg-sky-blue-50 text-sky-blue-700" /> {t('view')}
                </MenuItem>
              </Link>
            </MenuList>
          </div>
        }
      >
        <Button variant={ButtonVariant.SECONDARY}>
          <MoreIcon />
        </Button>
      </Popover>
    </div>
  );
};
const ApplyVoucherForPortfolioDialog = ({
  children,
  open,
  onOpenChange,
  portfolioId,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children: any;
  portfolioId: string;
}) => {
  const { t } = useTranslation('portfolio');
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    setError,
  } = useForm<{ voucherCode: string }>();
  const validate = useValidateVoucher();
  const apply = useApplyVoucher();
  const voucher = watch('voucherCode');

  const onSubmit = async () => {
    try {
      await validate.mutateAsync(voucher);
      await apply.mutateAsync({ voucherCode: voucher, portfolioId });
      toast.success(t('dashboard.gift_card.success'));
      trackRedeem(voucher);
      onOpenChange(false);
    } catch (err) {
      if (err instanceof Error) setBackendValidationErrors(err, setError);
    }
  };

  const isDisabled = validate.isLoading || apply.isLoading;

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      render={({ close, labelId }) => {
        return (
          <form tw="flex flex-col gap-8 p-4 md:p-8" onSubmit={handleSubmit(onSubmit)}>
            <h2 tw="text-lg font-bold text-gray-900 max-w-lg" id={labelId}>
              {t('dashboard.gift_card.title')}
            </h2>
            <div>
              <Input
                {...register('voucherCode', {
                  required: t('required', { ns: 'general' }) as string,
                  minLength: 9,
                  maxLength: 9,
                })}
                error={'voucherCode' in errors}
                key="name"
                placeholder={t('voucher.redeem.code.placeholder', { ns: 'gift' })}
                minLength={9}
                maxLength={9}
                disabled={isDisabled}
              />
              {errors.voucherCode && <div tw="text-sm text-dawn-300 mt-2">{errors.voucherCode.message}</div>}
            </div>
            <div tw="flex flex-row flex-wrap gap-4 justify-end">
              <Button
                variant={ButtonVariant.SECONDARY}
                onClick={() => {
                  close();
                  onOpenChange(false);
                }}
                disabled={isDisabled}
              >
                {t('close', { ns: 'general' })}
              </Button>
              <Button type="submit" variant={ButtonVariant.PRIMARY} disabled={isDisabled}>
                {t('dashboard.gift_card.apply_voucher')}
              </Button>
            </div>
          </form>
        );
      }}
    >
      {children}
    </Dialog>
  );
};
