import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import 'twin.macro';
import { PaymentHistory } from './PaymentHistory';

export const Payments = () => {
  // const portfolio = usePortfolio();
  // const navigate = useNavigate();

  // Don't allow exited portfolios to access this route
  // if (portfolio.status === PortfolioStatus.EXITED) {
  //   navigate('/', { replace: true });
  //   return null;
  // }

  return (
    <div tw="flex flex-col py-8 md:px-6">
      <Outlet />
      <Routes>
        <Route index element={<Overview />} />
        {/*<Route path="set-up/*" element={<SetUp />} />*/}
        {/*<Route path="top-up" element={<TopUp />} />*/}
        <Route path="*" element={<Navigate to="" replace />} />
      </Routes>
    </div>
  );
};

const Overview = () => {
  // const { t } = useTranslation('portfolio');
  // const navigate = useNavigate();
  // const portfolio = usePortfolio();

  // const isCorporate = portfolio.beneficiaryType === BeneficiaryType.EMPLOYEE;
  return (
    <>
      <div tw="flex flex-col md:flex-row">
        <PaymentHistory />
      </div>
    </>
  );
};
